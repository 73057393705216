<template>
    <div class="create_new_inquiry">
        <b-spinner variant="primary" v-if="spinner == true" large class="pageLoadSpinners center"/>
        <!-- Personal Information -->
        <validation-observer ref="visaEditApplication">
        <b-card
            title="Father’s Details"
        >

            <!-- form -->
            <b-form>
                <b-row class="personal_information_wrapper">
                    <b-col md="12">
                        <b-row>
                            <!-- Name -->
                            <b-col md="3">
                                <b-form-group label="First Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="father’s first name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.fatherFirstName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="50"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                             <b-col md="3">
                                <b-form-group label="Last Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="father’s last name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.fatherLastName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="50"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Date -->
                            <b-col md="3">
                                <b-form-group label="Date of Birth">
                                <validation-provider
                                    #default="{ errors }"
                                    name="father’s date of birth"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="example-input"
                                        v-model="formData.fatherDob"
                                        type="date"
                                        placeholder="YYYY-MM-DD"
                                        autocomplete="off"
                                        show-decade-nav
                                        @change="changeBirthDate('father')"
                                        :state="errors.length > 0 ? false:null"
                                        :max="getMaxDate()"
                                        class="input-date"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                            
                            <!-- father Place Of Birth  -->
                            <b-col md="3">
                                <b-form-group label="Place Of Birth">
                                <validation-provider
                                    #default="{ errors }"
                                    name="father’s place of birth"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.fatherPlaceOfBirth"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="100"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Nationality  -->
                            <b-col md="3">
                                <b-form-group label="Nationality">
                                <validation-provider
                                    #default="{ errors }"
                                    name="father’s nationality"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.fatherNationality"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="100"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Place of Residence (City & State): -->
                            <b-col md="3">
                                <b-form-group label="Place of Residence (City & State)">
                                <validation-provider
                                    #default="{ errors }"
                                    name="father’s place of residence"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="formData.fatherPlaceofResidence"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="100"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <b-row class="match-height">
            <!-- Communication Address -->
            <b-col md="12">
                <b-card
                    title="Mother’s Details"
                >
                    <!-- form -->
                    <b-form>
                        <b-row>
                            <!-- Name -->
                            <b-col md="3">
                                <b-form-group label="First Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="mother’s first name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.motherFirstName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="50"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                             <b-col md="3">
                                <b-form-group label="Last Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="mother’s last name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.motherLastName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="50"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Date -->
                            <b-col md="3">
                                <b-form-group label="Date of Birth">
                                <validation-provider
                                    #default="{ errors }"
                                    name="mother’s date"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="example-input"
                                        v-model="formData.motherDob"
                                        type="date"
                                        placeholder="YYYY-MM-DD"
                                        autocomplete="off"
                                        show-decade-nav
                                        @change="changeBirthDate('mother')"
                                        :state="errors.length > 0 ? false:null"
                                        :max="getMaxDate()"
                                        class="input-date"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- PLace of birth  -->
                            <b-col md="3">
                                <b-form-group label="Place of Birth">
                                <validation-provider
                                    #default="{ errors }"
                                    name="mother’s place of birth"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.motherPlaceOfBirth"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="100"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                            
                            <!-- Nationality  -->
                            <b-col md="3">
                                <b-form-group label="Nationality">
                                <validation-provider
                                    #default="{ errors }"
                                    name="mother’s nationality"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.motherNationality"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="100"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Place of Residence (City & State): -->
                            <b-col md="3">
                                <b-form-group label="Place of Residence (City & State)">
                                <validation-provider
                                    #default="{ errors }"
                                    name="mother’s place of residence"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="formData.motherPlaceofResidence"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="100"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="match-height">
            <!-- Communication Address -->
            <b-col md="12">
                <b-card
                    title="Sponsor(s) Details"
                >
                    <!-- form -->
                    <b-form>
                        <b-row>
                            <!-- Name -->
                            <b-col md="3">
                                <b-form-group label="First Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="sponsor’s first name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.sponsorFirstName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="50"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                             <b-col md="3">
                                <b-form-group label="Last Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="sponsor’s last name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="formData.sponsorLastName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="50"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Age -->
                             <b-col md="3">
                                <b-form-group label="Age">
                                <validation-provider
                                    #default="{ errors }"
                                    name="sponsor’s age"
                                    rules="required|numeric"
                                >
                                    <b-form-input
                                        v-model="formData.sponsorAge"
                                        :state="errors.length > 0 ? false:null"
                                        @keypress="isOnlyInteger"
                                        placeholder="Enter Text"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- PLace of birth  -->
                            <b-col md="3">
                                <b-form-group label="Address">
                                <validation-provider
                                    #default="{ errors }"
                                    name="sponsor’s address"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="formData.sponsorAddress"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="100"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Annual Income: -->
                            <b-col md="3">
                                <b-form-group label="Annual Income">
                                <validation-provider
                                    #default="{ errors }"
                                    name="sponsor’s annual income"
                                    :rules="{required:true,regex:/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/}"
                                >
                                    <b-form-input
                                        v-model="formData.sponsorAnnualIncome"
                                        @keypress="isOnlyInteger"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                            
                            <!-- Employment Details: -->
                            <b-col md="3">
                                <b-form-group label="Employment Details">
                                <validation-provider
                                    #default="{ errors }"
                                    name="sponsor’s employment details"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="formData.sponsorEmploymentDetails"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        maxLength="250"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>

        </validation-observer>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText,BFormDatepicker,
  BFormTextarea,BSpinner
} from 'bootstrap-vue'
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
import { extend } from 'vee-validate';
import { required,max,numeric,min,alpha,regex } from 'vee-validate/dist/rules';

extend('required',required)
extend('max',max)
extend('min',min)
extend('alpha',alpha)
extend('numeric',numeric)
extend('regex',regex)
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dbCollections } from "@/utils/firebaseCollection.js";
import { ValidationFunction } from '@/utils/globalValidations.js';

import vSelect from 'vue-select'

const db = firebase.firestore()

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BSpinner,
        BButton,
        BFormDatepicker,
        BFormTextarea,
        vSelect,
    },
    data() {
        return {
            formData:{
                fatherFirstName:'',
                fatherLastName:'',
                fatherDob:'',
                fatherPlaceOfBirth:'',
                fatherNationality:'',
                fatherPlaceofResidence:'',
                motherFirstName:'',
                motherLastName:'',
                motherDob:'',
                motherPlaceOfBirth:'',
                motherNationality:'',
                motherPlaceofResidence:'',
                sponsorFirstName:'',
                sponsorLastName:'',
                sponsorAge:'',
                sponsorAddress:'',
                sponsorAnnualIncome:'',
                sponsorEmploymentDetails:'',
            },
            spinner:false,
        }
    },
    beforeDestroy() {
        this.$root.$off('studentEditVisaApplication')
        this.$root.$off('studentEditCancelVisaApplication')
    },
    created() {
        var self = this
        this.$root.$off('studentEditVisaApplication')
        this.$root.$on('studentEditVisaApplication',()=>{
            self.saveStudentData()
        })
        this.$root.$off('studentEditCancelVisaApplication')
        this.$root.$on('studentEditCancelVisaApplication',()=>{
            self.$router.go(-1);
        })
        if(self.$route.params.id)
        {
            db.collection(dbCollections.VISAAPPLICATION).doc(this.$route.params.id).get().then((doc)=>{
                if(doc.exists)
                {
                    self.formData = doc.data()
                }
            }).catch((error)=>{
                console.error(error)
            })
        }
    },
    methods: {
        addZero(val)
        {
            if(val<10)
            {
                return `0`+val
            }
            else
            {
                return val
            }
        },
        getMaxDate()
        {
            return `${this.addZero(new Date().getFullYear())}-${this.addZero(new Date().getMonth()+1)}-${this.addZero(new Date().getDate())}`
        },
        isOnlyInteger(event) {
            ValidationFunction.OnlyIntegerNumber(event);
        },
        saveStudentData() {
            var self = this
            let currentUser = firebase.auth().currentUser;
            let currentUserObject = JSON.parse(localStorage.getItem("currentUser"));
            self.$refs.visaEditApplication.validate().then(success => {
                if (success) {
                    if(this.spinner) {
                        return;
                    }
                    this.spinner = true;
                    let docRef;
                    let objectData = {
                        ...this.formData,
                        'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    }
                    if(this.$route.params.id) {
                        docRef = db.collection(dbCollections.VISAAPPLICATION).doc(self.formData.id);
                    } else {
                        docRef = db.collection(dbCollections.VISAAPPLICATION).doc();
                        objectData.createdAt = firebaseTimeStamp.firestore.FieldValue.serverTimestamp();
                        objectData.id = docRef.id;
                        objectData.studentId = currentUser.uid;
                    }
                    docRef.set(objectData,{merge:true}).then(()=>{
                        if(!this.$route.params.id) {
                            db.collection(dbCollections.USERS).doc(currentUser.uid).update({
                                'visaApplicationId':docRef.id,
                                'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            }).then(()=>{
                                let notificationObj = {
                                    title:"Visa Application",
                                    message: `${currentUserObject.fullName} has filled out the visa application`,
                                    type: 'visaApplication',
                                    userId: currentUser.uid
                                }
                                this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then((resp)=>{
                                }).catch((error)=>{
                                    console.error(error);
                                });
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: 'Visa application created successfully',
                                        icon: 'CoffeeIcon',
                                        variant: 'success',
                                    },
                                })
                                this.spinner = false;
                                self.$root.$emit('stopSpinner')
                                self.$router.go(-1)
                            }).catch((error)=>{
                                this.spinner = false;
                                self.$root.$emit('stopSpinner')
                                console.error(error)
                            })
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Visa application edited successfully',
                                    icon: 'CoffeeIcon',
                                    variant: 'success',
                                },
                            })
                            this.spinner = false;
                            self.$root.$emit('stopSpinner')
                            self.$router.go(-1)
                        }
                    }).catch((error)=>{
                        this.spinner = false;
                        self.$root.$emit('stopSpinner')
                        console.error(error)
                    })
                }
                else
                {
                    this.spinner = false;
                    self.$root.$emit('stopSpinner')
                }
            })
        },
        changeBirthDate(type){                    
            var self = this
            var maxDate = self.getMaxDate().split('-')
            if(type == 'father') {
                var selectedDate = self.formData.fatherDob.split('-')
            }
            if(type == 'mother') {
                var selectedDate = self.formData.motherDob.split('-')
            }

            if(selectedDate[0]>maxDate[0])//If Year not valid
            {
                if(type == 'father') {
                    self.formData.fatherDob = ''
                }
                if(type == 'mother') {
                    self.formData.motherDob = ''
                }
                return;
            }
            else if(selectedDate[0]==maxDate[0])
            {
                if(selectedDate[1]>maxDate[1])//If month not valid
                {
                    if(type == 'father') {
                        self.formData.fatherDob = ''
                    }
                    if(type == 'mother') {
                        self.formData.motherDob = ''
                    }
                    return;
                }
                else
                {
                    if(selectedDate[1]==maxDate[1] && selectedDate[2]>maxDate[2])//If date not valid
                    {
                        if(type == 'father') {
                            self.formData.fatherDob = ''
                        }
                        if(type == 'mother') {
                            self.formData.motherDob = ''
                        }
                        return;
                    }
                }
            }
        }
    },
}
</script>
